import jquery from "jquery";
window.jQuery = window.$ = jquery;

// Bootstrap Framework
import "./scss/index.scss";
import "@popperjs/core";
import "bootstrap";

import "slick-carousel";
import hcSticky from "hc-sticky";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { Moncal } from "./moncaljs/index";

//#region bootstrap navbar + hamburger + body scroll lock
/* var navbarMain = document.getElementById("navbarMain");
navbarMain.addEventListener("show.bs.collapse", function () {
  $(".navbar-toggler .hamburger").addClass("is-active");
  let scrollableNav = document.querySelector("#navbarMain");
  disableBodyScroll(scrollableNav);
});
navbarMain.addEventListener("hidden.bs.collapse", function () {
  $(".navbar-toggler .hamburger").removeClass("is-active");
  let scrollableNav = document.querySelector("#navbarMain");
  enableBodyScroll(scrollableNav);
}); */
//#endregion

//#region stickybar
document.addEventListener("DOMContentLoaded", function () {
  new hcSticky(".stickybar", {
    stickTo: "body",
    stickyClass: "stickybar--sticked",
  });
});
//#endregion

//#region fade in section
const observer = new IntersectionObserver(entries => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      document.querySelectorAll(".animated")[0].classList.add("fadeInTop");
      document.querySelectorAll(".animated")[1].classList.add("fadeInTop");
      document.querySelectorAll(".animated")[2].classList.add("fadeInTop");
      document.querySelectorAll(".animated")[3].classList.add("fadeInTop");

    }
  })
})
observer.observe(document.querySelector(".animated-row"));
/* $(document).ready(function () {
  $(window).scroll(function () {
    $('.animated').each(function (i) {
      var bottom_of_object = $(this).offset().top + $(this).outerHeight();
      var bottom_of_window = $(window).scrollTop() + $(window).height();
      if (bottom_of_window > bottom_of_object) {

        $(this).animate({ 'opacity': '1' }, 1000);
      }
    });
  });
}) */


//#endregion
